import { Component } from "@angular/core";
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { RouteHistory } from 'src/app/services/route-history.service';
import { SpinnerSharedService } from "./services/shared/spinner.shared.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent {

  constructor(
    private router: Router,
    private routeHistory: RouteHistory,
    private spinner: NgxSpinnerService,
    private spinnerSharedService: SpinnerSharedService
  ) {
    this.routeHistory.recordHistory();
    this.spinnerSharedService.showSpinnerSubject().subscribe(show => {
      if (show) {
        this.spinner.show();
      } else {
        this.spinner.hide();
      }
    });
  }
}
