import { AppComponent } from "./app.component";
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { RouterModule } from "@angular/router";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BsDropdownModule, BsModalService } from "ngx-bootstrap";
import { ToastrModule } from "ngx-toastr";
import { TagInputModule } from "ngx-chips";
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { PaginationModule } from "ngx-bootstrap/pagination";
import { NgxSpinnerModule } from "ngx-spinner";

import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";
import { AuthLayoutComponent } from "./layouts/auth-layout/auth-layout.component";

import { ComponentsModule } from './components/components.module';
import { FormsModule as TreadFormsModule } from './forms/forms.module';
import { SharedFormsModule as TreadSharedFormsModule } from './forms-shared/shared-forms.module';
import { ModalsModule } from './modals/modals.module';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { GarageComponent } from './pages/garage/garage.component';
import { LogsComponent } from './pages/logs/logs.component';
import { ServiceLogsComponent } from './views/logs/service-logs/service-logs.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { AccountSettingsComponent } from './views/settings/account-settings/account-settings.component';
import { NotificationSettingsComponent } from './views/settings/notification-settings/notification-settings.component';
import { ServicesSettingsComponent } from './views/settings/services-settings/services-settings.component';
import { RemindersSettingsComponent } from './views/settings/reminders-settings/reminders-settings.component';
import { AddServiceLogComponent } from './views/logs/service-logs/add-service-log/add-service-log.component';
import { ServiceLogsSplashComponent } from './views/logs/service-logs/service-logs-splash/service-logs-splash.component';
import { AddVehicleComponent } from './views/garage/add-vehicle/add-vehicle.component';
import { GarageSplashComponent } from './pages/garage-splash/garage-splash.component';
import { DetailsComponent } from './pages/details/details.component';
import { VehicleSettingsComponent } from './views/settings/vehicle-settings/vehicle-settings.component';
import { EditServiceLogComponent } from './views/logs/service-logs/edit-service-log/edit-service-log.component';
import { PreferencesSettingsComponent } from './views/settings/preferences-settings/preferences-settings.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { ErrorComponent } from './pages/error/error.component';

import { AuthInterceptor } from './interceptors/auth.interceptor';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { SpinnerInterceptor } from './interceptors/spinner.interceptor';
import { RouteHistory } from './services/route-history.service';
import { ContactComponent } from './pages/contact/contact.component';

// TODO move views to their own component?
import { AddGasLogComponent } from './views/logs/gas-logs/add-gas-log/add-gas-log.component';
import { EditGasLogComponent } from './views/logs/gas-logs/edit-gas-log/edit-gas-log.component';
import { GasLogsSplashComponent } from './views/logs/gas-logs/gas-logs-splash/gas-logs-splash.component';
import { GasLogsComponent } from './views/logs/gas-logs/gas-logs.component';
import { RegistrationLogsComponent } from './views/logs/registration-logs/registration-logs.component';
import { AddRegistrationLogComponent } from './views/logs/registration-logs/add-registration-log/add-registration-log.component';
import { EditRegistrationLogComponent } from './views/logs/registration-logs/edit-registration-log/edit-registration-log.component';
import { RegistrationLogsSplashComponent } from './views/logs/registration-logs/registration-logs-splash/registration-logs-splash.component';
import { IncidentLogsComponent } from './views/logs/incident-logs/incident-logs.component';
import { AddIncidentLogComponent } from './views/logs/incident-logs/add-incident-log/add-incident-log.component';
import { EditIncidentLogComponent } from './views/logs/incident-logs/edit-incident-log/edit-incident-log.component';
import { IncidentLogsSplashComponent } from './views/logs/incident-logs/incident-logs-splash/incident-logs-splash.component';
import { PartLogsComponent } from './views/logs/part-logs/part-logs.component';
import { AddPartLogComponent } from './views/logs/part-logs/add-part-log/add-part-log.component';
import { EditPartLogComponent } from './views/logs/part-logs/edit-part-log/edit-part-log.component';
import { PartLogsSplashComponent } from './views/logs/part-logs/part-logs-splash/part-logs-splash.component';
import { ReleaseNotesComponent } from './pages/release-notes/release-notes.component';

@NgModule({
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    ComponentsModule,
    RouterModule,
    BsDropdownModule.forRoot(),
    AppRoutingModule,
    ToastrModule.forRoot(),
    CollapseModule.forRoot(),
    TagInputModule,
    NgbModule,
    TreadFormsModule,
    TreadSharedFormsModule,
    PaginationModule,
    ModalsModule,
    DirectivesModule,
    NgxSpinnerModule
  ],
  declarations: [
    AppComponent,

    AdminLayoutComponent,
    AuthLayoutComponent,

    GarageComponent,
    LogsComponent,
    ServiceLogsComponent,
    SettingsComponent,
    AccountSettingsComponent,
    NotificationSettingsComponent,
    ServicesSettingsComponent,
    RemindersSettingsComponent,
    AddServiceLogComponent,
    ServiceLogsSplashComponent,
    AddVehicleComponent,
    GarageSplashComponent,
    DetailsComponent,
    VehicleSettingsComponent,
    EditServiceLogComponent,
    PreferencesSettingsComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    ErrorComponent,
    ContactComponent,
    AddGasLogComponent,
    EditGasLogComponent,
    GasLogsSplashComponent,
    GasLogsComponent,
    RegistrationLogsComponent,
    AddRegistrationLogComponent,
    EditRegistrationLogComponent,
    RegistrationLogsSplashComponent,
    IncidentLogsComponent,
    AddIncidentLogComponent,
    EditIncidentLogComponent,
    IncidentLogsSplashComponent,
    PartLogsComponent,
    AddPartLogComponent,
    EditPartLogComponent,
    PartLogsSplashComponent,
    ReleaseNotesComponent
  ],
  providers: [
    RouteHistory,
    BsModalService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: SpinnerInterceptor, multi: true }
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule {}
