import { VehicleDetails } from "src/app/models/VehicleDetails";

export class UserVehicle {
    id: string;
    nickname: string;
    mileage: number
    milesDrivenPerYear: number
    favorite: boolean
    make: string;
    model: string;
    year: number;
    trim: string;
    category: string;
    details: VehicleDetails;
}