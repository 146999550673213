import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subscription } from '../models/Subscription';
import { map } from 'rxjs/operators';
import { AuthService } from './auth.service';
import Utils from '../utils/utils';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
}

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {
  private subscribeUrl = environment.apiUrl + '/user/subscription/activate';
  private approveSubscriptionUrl = environment.apiUrl + '/user/subscription/approve';
  private cancelSubscriptionUrl = environment.apiUrl + '/user/subscription/cancel';

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) { }

  subscribe(): Observable<Subscription> {
    return this.http.post<any>(this.subscribeUrl, null, httpOptions).pipe(
      map(subscription => {
        if (Utils.isEmpty(subscription)) {
          this.setLoggedInUserToSubscribed();
        }
        return subscription;
      })
    );
  }

  approveSubscription(): Observable<any> {
    return this.http.post<any>(this.approveSubscriptionUrl, null, httpOptions).pipe(
      map(() => this.setLoggedInUserToSubscribed())
    );
  }

  cancelSubscription(): Observable<any> {
    return this.http.post<any>(this.cancelSubscriptionUrl, null, httpOptions).pipe(
      map(() => this.setLoggedInUserToNotSubscribed())
    );
  }

  setLoggedInUserToSubscribed() {
    this.setLoggedInUserSubscriptionStatus(true);
  }

  setLoggedInUserToNotSubscribed() {
    this.setLoggedInUserSubscriptionStatus(false);
  }

  setLoggedInUserSubscriptionStatus(subscribed: boolean) {
    const loggedInUser = this.authService.getLoggedInUser();
    loggedInUser.subscribed = subscribed;
    this.authService.setLoggedInUser(loggedInUser);
  }
}
